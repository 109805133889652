import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { format } from "date-fns";
import { pl } from "date-fns/locale";

const Box = styled.div`
  border: 1px solid black;
  margin-bottom: 2px;

  padding: 0 5px 0 5px;
  font-size: 1.1em;
  min-height: 60px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: baseline;
  justify-content: flex-start;
  background: ${({ status }) => {
    if (status.localeCompare("Available") === 0) {
      return "#497f63";
    } else if (status.localeCompare("Unavailable") === 0) {
      return "#af2e2e";
    } else if (status.localeCompare("LastOne") === 0) {
      return "orange";
    }
  }};
  order: ${(order) => order};
`;

const Text = styled.p`
  margin: 0;
  text-align: center;
  color: white;
  width: 100%;
`;

Box.propTypes = {
  status: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
};

const AvailabilityWeek = ({ week, order }) => {
  return (
    <Box status={week.Status} order={order}>
      <Text>
        {format(week.DateFrom, "d.M", { locale: pl })} -{" "}
        {format(week.DateTo, "d.M", { locale: pl })}
      </Text>
      <Text>Cena (za dobę): {week.Price} zł</Text>
    </Box>
  );
};

AvailabilityWeek.propTypes = {
  week: PropTypes.object.isRequired,
  order: PropTypes.number.isRequired,
};

export default AvailabilityWeek;
