import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import { pl } from "date-fns/locale";
import styled from "styled-components";

const Box = styled.div`
  text-align: center;
  font-style: italic;
  margin-bottom: 5px;
`;

const Text = styled.p`
  color: #777777;
  text-transform: capitalize;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

const AvailabilityHeader = ({ month }) => {
  return (
    <Box>
      <Text>{format(month, "LLLL", { locale: pl })}</Text>
    </Box>
  );
};

AvailabilityHeader.propTypes = {
  month: PropTypes.object.isRequired,
};

export default AvailabilityHeader;
