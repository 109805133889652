import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/Layout";
import React from "react";
import {
  getMonth,
  isSameMonth,
  differenceInDays,
  startOfMonth,
  endOfMonth,
  setMonth,
  getUnixTime,
  parseISO,
} from "date-fns";
import { has } from "lodash";
import styled from "styled-components";
import AvailabilityHeader from "../components/AvailabilityHeader";
import AvailabilityWeek from "../components/AvailabilityWeek";
import Title from "../components/Title";
import { PropTypes } from "prop-types";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const Col = styled.div`
  flex-basis: 9.5rem;
  margin: 0 0.15rem 0 0.15rem;
  padding: 0;

  flex-shrink: 0;
  flex-grow: 0;

  display: flex;
  flex-direction: column;
  font-style: italic;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const Container = styled.div`
  > div {
    width: 100%;
  }
  @media (max-width: ${({ breaks }) => breaks[0].break}) {
    > div {
      width: ${({ breaks }) => breaks[0].size};
    }
  }
  @media (min-width: ${({ breaks }) => breaks[1].break}) {
    > div {
      width: ${({ breaks }) => breaks[1].size};
    }
  }
  @media (min-width: ${({ breaks }) => breaks[2].break}) {
    > div {
      width: ${({ breaks }) => breaks[2].size};
    }
  }
  @media (min-width: ${({ breaks }) => breaks[3].break}) {
    > div {
      width: ${({ breaks }) => breaks[3].size};
    }
  }

  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
`;

Container.propTypes = {
  breaks: PropTypes.arrayOf(
    PropTypes.shape({
      break: PropTypes.string.isRequired,
      size: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  font-size: 1.3rem;
  width: 100%;
  align-items: center;
  align-content: center;
  > p {
    margin: 0;
    flex-basis: 0;
    flex-grow: 1;
  }
`;

const AvailabilityPage = () => {
  const res = useStaticQuery(query);
  const data = res.allStrapiAvailability.edges[0].node.year;
  const legenda = res.strapiWolneMiejscaLegenda.Legenda;
  const monthForEntry = (entry) => {
    if (isSameMonth(entry.DateFrom, entry.DateTo)) {
      return getMonth(entry.DateFrom);
    } else {
      const days_start = differenceInDays(
        endOfMonth(entry.DateFrom),
        entry.DateFrom
      );
      const days_end = differenceInDays(
        entry.DateTo,
        startOfMonth(entry.DateTo)
      );
      if (days_start > days_end) {
        return getMonth(entry.DateFrom);
      } else {
        return getMonth(entry.DateTo);
      }
    }
  };
  const entriesByMonth = () => {
    const entries = data.Entries;
    let ret = {};
    for (const entry of entries) {
      const new_entry = {
        ...entry,
        DateFrom: parseISO(entry.DateFrom),
        DateTo: parseISO(entry.DateTo),
      };
      const month = monthForEntry(new_entry);
      if (!has(ret, month)) {
        ret[month] = [];
      }
      ret[month].push(new_entry);
    }
    return ret;
  };
  const months = Object.entries(entriesByMonth());
  const breaks = [
    {
      break: `576px`,
      size: `20rem`,
    },
    {
      break: `576px`,
      size: months.length >= 6 ? `30rem` : `20rem`,
    },
    {
      break: `780px`,
      size: months.length >= 6 ? `30rem` : `100%`,
    },
    {
      break: `1200px`,
      size: `100%`,
    },
  ];
  return (
    <Layout pageTitle="Wolne miejsca">
      <Title>Wolne miejsca - sezon {data.Year}</Title>

      <Container breaks={breaks}>
        <Row>
          {months.map(([month, weeks]) => {
            return (
              <Col key={month}>
                <AvailabilityHeader month={setMonth(new Date(), month)} />
                {weeks.map((week) => {
                  return (
                    <AvailabilityWeek
                      week={week}
                      key={week.id}
                      order={getUnixTime(week.DateFrom)}
                    />
                  );
                })}
              </Col>
            );
          })}
        </Row>
      </Container>
      <LegendContainer>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{legenda}</ReactMarkdown>
      </LegendContainer>
    </Layout>
  );
};
const query = graphql`
  query {
    allStrapiAvailability {
      edges {
        node {
          year {
            Year
            Entries {
              DateFrom
              DateTo
              Price
              Status
              Year
              id
            }
          }
        }
      }
    }
    strapiWolneMiejscaLegenda {
      Legenda
    }
  }
`;

export default AvailabilityPage;
